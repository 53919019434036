import { useMemo } from "react";
import { cssBundleHref } from "@remix-run/css-bundle";
import { LoaderFunctionArgs, type LinksFunction, json } from "@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError } from
"@remix-run/react";
import { ColorSchemeScript, MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import faviconAssetUrl from './assets/favicon.svg';

import "@mantine/core/styles.css?__remix_sideEffect__";
import "@mantine/notifications/styles.css?__remix_sideEffect__";


export const loader = async ({ request }: LoaderFunctionArgs) => {

  const env = {
    SUPABASE_URL: process.env.SUPABASE_URL!,
    SUPABASE_ANON_KEY: process.env.SUPABASE_ANON_KEY!
  };

  return json({
    env
  });
};

export const links: LinksFunction = () => [
{ rel: 'icon', type: 'image/svg+xml', href: faviconAssetUrl },
...(cssBundleHref ? [{ rel: "stylesheet", href: cssBundleHref }] : [])];



export default function App() {

  const { env } = useLoaderData<typeof loader>();

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <ColorSchemeScript />
      </head>
      <body>
        <MantineProvider>
          <ModalsProvider>
            <Notifications />
            <Outlet />
            <ScrollRestoration />
            <Scripts />
            <LiveReload />
          </ModalsProvider>
        </MantineProvider>
      </body>
    </html>);

}

export function ErrorBoundary() {

  const error = useRouteError();

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <ColorSchemeScript />
      </head>
      <body>
        <MantineProvider>
          <ModalsProvider>
            <h1>
              {isRouteErrorResponse(error) ?
              `${error.status} ${error.statusText}` :
              error instanceof Error ?
              error.message :
              "Unknown Error"}
            </h1>
            <Scripts />
            <LiveReload />
          </ModalsProvider>
        </MantineProvider>
      </body>
    </html>);

}